import React from 'react';
import classnames from 'classnames';
import './index.module.less';

interface TopicPanelProps {
  className?: string;
  type?: string; // primary, black
  title?: string;
  enTitle?: string;
  id?: any;
}

const TopicPanel: React.FC<TopicPanelProps> = (params) => {
  const {
    className,
    children,
    type = 'primary',
    title,
    enTitle,
    id
  } = params;

  const cnames = classnames("topic-panel", type, className );

  return (
    <section id={id} className={cnames}>
      <div className="topic-panel--title">
        <div className="topic-panel--title_en">
          <div className="topic-panel--title_en-left"></div>
          <div className="topic-panel--title_en-center">
            <span>{enTitle}</span>
          </div>
          <div className="topic-panel--title_en-right"></div>
        </div>
        <h2 className="topic-panel--title_cn">{title}</h2>
      </div>
      <article className="topic-panel--body">
        {children}
      </article>
    </section>
  );
}

export default TopicPanel;