import React, { ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react'
import classnames from 'classnames'
import Link from 'components/Base/Link'

export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Blue = 'blue',
  Secondary = 'secondary',
  Ghost = 'ghost',
  Text = 'text',
  Link = 'link',
  Gradients = 'gradients',
  White = 'white',
}
interface BaseButtonProps {
  className?: string
  size?: string
  href?: string
  ghost?: boolean
  btnType?: ButtonType | string
  plain?: boolean
  round?: boolean
  icon?: string
  useLink?: boolean
  shape?: 'default' | 'circle'
  target?: string
}
type NativeButtonProps = BaseButtonProps & ButtonHTMLAttributes<HTMLElement>
type AnchorButtonProps = BaseButtonProps & AnchorHTMLAttributes<HTMLElement> & { target?: string }
export type ButtonProps = Partial<NativeButtonProps & AnchorButtonProps>

const Button: React.FC<ButtonProps> = (props) => {
  const {
    btnType = 'default',
    className,
    children,
    size = '',
    href,
    ghost,
    plain,
    round,
    disabled,
    icon,
    useLink,
    target = '_self',
    shape = 'default',
    ...restProps
  } = props

  const btnClasses = {
    default: 'sd-button-default',
    primary: 'sd-button-primary',
    blue: 'sd-button-blue',
    secondary: 'sd-button-secondary',
    ghost: 'sd-button-ghost',
    text: 'sd-button-text',
    link: 'sd-button-link',
    gradients: 'sd-button-gradients',
    small: 'sd-button-small',
    white: 'sd-button-white',
    international: 'sd-button-international',
    black: 'sd-button-black',
  }

  const btnShape = {
    default: '',
    circle: 'sd-button-circle',
  }

  const cnames = classnames('sd-button', className, {
    [btnClasses[btnType]]: btnType,
    [btnClasses[size]]: size,
    'sd-button-ghost': ghost || btnType === 'ghost',
    'is-plain': plain,
    'is-round': round,
    'has-icon': icon,
    [btnShape[shape]]: shape,
  })

  if (href && useLink && !disabled) {
    return (
      <Link {...restProps} className={cnames} to={href} target={target}>
        <span>{children}</span>
        {icon && <i className={classnames('sd-icon', icon)}></i>}
      </Link>
    )
  }

  if (href && !disabled) {
    return (
      <a className={cnames} href={href} {...restProps} target={target}>
        <span>{children}</span>
        {icon && <i className={classnames('sd-icon', icon)}></i>}
      </a>
    )
  }

  return (
    <button className={cnames} disabled={disabled} {...restProps}>
      <span>{children}</span>
      {icon && <i className={classnames('sd-icon', icon)}></i>}
    </button>
  )
}

export default Button

export enum ButtonGroupAlign {
  Left = `justify-start text-left`,
  Center = `justify-center text-center`,
  Right = `justify-end text-right`,
  SpaceBetween = `justify-between align-justify`,
  SpaceAround = `justify-around`,
}
interface ButtonGroupProps {
  className?: string
  style?: React.CSSProperties
  align?: ButtonGroupAlign
  children?: any
}
export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const { className, style, align, children } = props

  const cnames = classnames('sd-button-group', className, align)

  return (
    <div className={cnames} style={style}>
      {children}
    </div>
  )
}
