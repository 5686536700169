import React from 'react';
import classnames from 'classnames';
import './index.module.less';

import floatQrcode from 'assets/images/topic/ds2021/ds2021-qrcode-dhzx.png';

const Float: React.FC = params => {

  const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div className="topic-ds2021-float">
      <div className="topic-ds2021-float--header">
        <ul>
          <li>
            <i className="icon icon-consult"></i>
            <span>大会咨询</span>
            <div className="hover-dialog">
              {/* <i className="icon icon-close"></i> */}
              <img src={floatQrcode} alt="" className="qrcode" />
              <p>扫码咨询</p>
            </div>
          </li>
          <li>
            <a href="/topic/ds2021Integral.html">
              <i className="icon icon-present"></i>
              <span>积分有礼</span>
            </a>
            {/* <div className="hover-dialog">
              <i className="icon icon-expect"></i>
              <p>即将开放<br />敬请期待</p>
            </div> */}
          </li>
          {/* <li>
            <i className="icon icon-demo"></i>
            <span>Demo center</span>
            <div className="hover-dialog">
              <i className="icon icon-expect"></i>
              <p>即将开放<br />敬请期待</p>
            </div>
          </li> */}
        </ul>
      </div>
      <div className="topic-ds2021-float--footer" onClick={scrollTop}></div>
    </div>
  );
}

export default Float;