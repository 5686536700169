import fetch from './fetch'
import qs from 'qs'
const EE_API = process.env.EE_API
const BASE_URL = process.env.API_URL
const INTERNATIONAL_API = process.env.INTERNATIONAL_API

export const fetchWhoami = () => fetch({ url: `${BASE_URL}/whoami`, method: 'post' })
export const fetchLogin = (data) => fetch({ url: `${BASE_URL}/user/login`, method: 'post', data: qs.stringify(data) })
export const fetchLogout = () => fetch({ url: `${BASE_URL}/user/logout`, method: 'post' })
export const fetchSms = (phone) =>
  fetch({ url: `${BASE_URL}/sms/quest_new/${phone}?_t=${Date.now().toString(36)}`, method: 'get' })
// 获取当前绑定的手机号验证码
export const fetchLoggedQuest = () =>
  fetch({ url: `${BASE_URL}/sms/logged_quest?_t=${Date.now().toString(36)}`, method: 'get' })
export const fetchSmsVerify = (phone, code) =>
  fetch({ url: `${BASE_URL}/sms/verify/${phone}/${code}?_t=${Date.now().toString(36)}`, method: 'get' })
// 校验手机号是否已注册
export const fetchCheckPhone = (phone) => fetch({ url: `${BASE_URL}/user/check_phone/${phone}`, method: 'get' })
export const fetchCrmLeadsCreate = (data) =>
  fetch({ url: `${BASE_URL}/crm/leads_create`, method: 'post', data: qs.stringify(data) })
export const fetchSubscription = (data) =>
  fetch({ url: `${BASE_URL}/product/subscription`, method: 'post', data: qs.stringify(data) })
export const fetchCompanySearch = (data) =>
  fetch({ url: `${BASE_URL}/company/search`, method: 'post', data: qs.stringify(data) })
// 修改手机号
export const fetchModifyPhone = (data) =>
  fetch({ url: `${BASE_URL}/user/modify_phone`, method: 'post', data: qs.stringify(data) })

// 接口地址：http://doc.eeapi.sensorsdata.cn/doc.html#/home （www-server）
// 获取'推荐用户增长'引导数据
export const fetchUGD = () => fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/ugd`, method: 'get' })
// 获取用户'最近体验demo'
export const fetchUGDLastUEDemo = () =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/ugd/last_ue_demo`, method: 'get' })
// 获取用户'最近浏览内容'
export const fetchUGDLastPVDemo = () =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/ugd/last_pv_demo`, method: 'get' })
// 获取推荐用户的'视频课程'资料
export const fetchUGDCourse = () =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/ugd/course`, method: 'get' })
// 获取用户'为您精选'数据
export const fetchUGDOptimization = () =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/ugd/optimization`, method: 'get' })
// 获取推荐用户'研究报告/白皮书'资料
export const fetchUGDReport = () =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/ugd/report`, method: 'get' })
// 注册表单行业列表(注意：salang参数存储在Cookie里面！！)
export const fetchIndustries = () =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/industries`, method: 'get' })
// 更新用户属性
export const updateUsers = (data) =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/users`, method: 'PATCH', data: qs.stringify(data) })
// 更新用户头像
export const updateAvatar = (data) =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/users/avatar`, method: 'PATCH', data: qs.stringify(data) })
// 生成微信二维码
export const fetchQrcode = (data) =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/mp/qrcode`, method: 'POST', data: qs.stringify(data) })
// 微信用户扫码事件回调
export const fetchQrcodeScanEvent = () => fetch({ url: `${BASE_URL}/wechat/scan_status?_t=kt8db3vf`, method: 'POST' })

// 国际官网
export const fetchWhoamiI18n = () => fetch({ url: `${process.env.I18n_API}/api/passport/user/whoami`, method: 'post' })
export const fetchLogoutI18n = () => fetch({ url: `${process.env.I18n_API}/api/passport/user/logout`, method: 'post' })

// 英文销售表单
export const fetchParadeI18n = (data) =>
  fetch({ url: `${INTERNATIONAL_API}/api/owe/sales/form`, method: 'post', data: data })

// 注销账号
export const logoutUser = (data) =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/deleteUser?reason=${data}`, method: 'post' })

// 获取用户收藏列表
export const getUserFavoritesData = () =>
  fetch({
    withCredentials: true,
    url: `${EE_API}/api/www/v1/helpCenter/collect`,
    method: 'get',
  })

// 新增用户收藏
// http://doc.eeapi.sensorsdata.cn/doc.html#/www-server/%E5%B8%AE%E5%8A%A9%E4%B8%AD%E5%BF%83/createCollectUsingPOST
export const addUserFavorite = (data) =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/helpCenter/collect`, method: 'POST', data })

// 删除用户收藏
// http://doc.eeapi.sensorsdata.cn/doc.html#/www-server/%E5%B8%AE%E5%8A%A9%E4%B8%AD%E5%BF%83/deleteCollectUsingDELETE
export const deleteUserFavorite = ({ id }) =>
  fetch({ withCredentials: true, url: `${EE_API}/api/www/v1/helpCenter/collect/${id}`, method: 'DELETE' })

export const fetchRegisterI18n = (data) =>
  fetch({ url: `${INTERNATIONAL_API}/api/client/user/register`, method: 'post', data })
export const fetchEnLogin = (data) =>
  fetch({
    url: `${INTERNATIONAL_API}/api/client/user/login`,
    method: 'post',
    data,
  })
export const checkEmail = (email) =>
  fetch({ url: `${INTERNATIONAL_API}/api/client/user/email?email=${email}`, method: 'get' })
export const checkGoogle = (access_token) =>
  fetch({ url: `${INTERNATIONAL_API}/api/www/google/oauth?access_token=${access_token}`, method: 'get' })
export const saveUserInfo = (data) => fetch({ url: `${INTERNATIONAL_API}/api/client/user/save`, method: 'post', data })
export const fetchActiveEmail = (code) =>
  fetch({ url: `${INTERNATIONAL_API}/api/client/user/activate?code=${code}`, method: 'get' })
export const fetchOauth = (code) =>
  fetch({ url: `${INTERNATIONAL_API}/api/client/user/oauth?code=${code}`, method: 'get' })
export const fetchEnWhoami = () => fetch({ url: `${INTERNATIONAL_API}/api/client/user/whoami`, method: 'get' })
export const fetchEnLogout = () => fetch({ url: `${INTERNATIONAL_API}/api/client/user/logout`, method: 'delete' })

// gt4 注册表单
export const fetchGA4From = (data) => {
  return fetch({ url: `${INTERNATIONAL_API}/api/owe/sales/form/ga`, method: 'post', data })
}
