import React from 'react'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'

interface BaseLinkProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
  [propName: string]: any
}
const BaseLink: React.FC<BaseLinkProps> = (props) => {
  const { locale } = useIntl()
  const { children, to, target } = props
  return (
    <Link {...props} to={locale === 'zh' ? to : `/${locale}${to}`} target={target}>
      {children}
    </Link>
  )
}

export default BaseLink
