import React, { createContext, useState, useEffect } from 'react'
import { fetchWhoami, fetchEnWhoami } from 'api/user'
import { saLogin, saQuick, _cookie } from 'utils/statistics'
import { Languages } from 'i18n/config'

export interface User {
  account_id?: string
  avatar?: string
  create_time?: string
  email?: string | null
  group_id?: string | null
  group_name?: string | null
  has_feedback?: boolean | null
  industry?: string | null
  ldap_id?: string | null
  phone?: string | null
  position?: string | null
  role?: string | null
  user_id?: string | null
  user_name?: string | null
  wx8381_openid?: string | null
  last_login_time?: string
  user_avatar_url?: string | null
  paraded?: boolean
}

interface ProvideProp {
  user?: User
  setUser?: React.Dispatch<React.SetStateAction<User | null>>
  isVerifyLogin?: boolean
}

export const providerContext = createContext<ProvideProp>({})

interface ProviderProp {
  value?: any
  isUpdate?: boolean
  lang?: string
  onUserInfo?: (data: User | null) => void
}

const Provider: React.FC<ProviderProp> = ({ children, value = {}, isUpdate, lang, onUserInfo }) => {
  const [user, setUser] = useState<User | null>(null)
  // 用于判断是否校验是否登录了
  const [isVerifyLogin, setIsVerifyLogin] = useState<boolean>(false)
  useEffect(() => {
    if (lang === Languages.TW || lang === Languages.EN || lang === Languages.JA) {
      getUserInfoI18n()
    } else {
      getUserInfo()
    }
  }, [isUpdate])

  // 国际获取用户信息
  const getUserInfoI18n = async () => {
    try {
      const res = await fetchEnWhoami()
      const { result } = res.data
      // 根据返回的用户信息 name/userId 未登录为null
      if (result !== null) {
        // 已登录
        saQuick('isReady', function () {
          result.userId && saLogin(result.userId)
        })
        setUser(result)
        onUserInfo && onUserInfo(result)
      } else {
        // 未登录
        onUserInfo && onUserInfo(null)
      }
      setIsVerifyLogin(true)
    } catch (error) {
      console.log(error)
    }
  }

  // 国内获取用户信息
  const getUserInfo = async () => {
    try {
      const res = await fetchWhoami()
      const { data, status } = res.data
      if (status === 200) {
        // 已登录
        saQuick('isReady', function () {
          data?.profile?.account_id && saLogin(data?.profile?.account_id)
        })
        setUser(data.profile)
        onUserInfo && onUserInfo(data.profile)
      } else {
        // 未登录
        onUserInfo && onUserInfo(null)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <providerContext.Provider
      value={{
        ...value,
        user,
        setUser,
        isVerifyLogin,
      }}
    >
      {children}
    </providerContext.Provider>
  )
}

export default Provider
