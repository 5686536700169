import React from 'react';
import classnames from 'classnames';
import Float from '../Float';
import './index.module.less';

import QRCode_dhzx from 'assets/images/topic/ds2021/ds2021-qrcode-dhzx.png';
import QRCode_gpzx from 'assets/images/topic/ds2021/qrcode-gpzx.png';

interface TopicFooterProps {
  className?: string;
  hideFloat?: boolean;
}

const TopicFooter: React.FC<TopicFooterProps> = (params) => {
  const { 
    className,
    hideFloat = false
  } = params;

  return (
    <footer className={classnames("topic-footer", className)}>
      <ul className="qrcode-list">
        <li>
          <img src={QRCode_dhzx} alt="" />
          <p>扫码咨询大会更多信息</p>
        </li>
        {/* <li>
          <img src={QRCode_gpzx} alt="" />
          <p>大会咨询</p>
        </li> */}
      </ul>
      <p className="copy">神策网络科技（北京）有限公司</p>
      { !hideFloat && <Float /> }
    </footer>
  );
}

export default TopicFooter;