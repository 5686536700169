import React, { useState } from 'react';
import classnames from 'classnames';
import './index.module.less';

interface TopicHeaderProps {
  className?: string;
  hideMenu?: boolean;
}

const TopicHeader: React.FC<TopicHeaderProps> = (params) => {
  const { 
    className,
    hideMenu = false
  } = params;
  const [spread, setSpread] = useState(false);

  return (
    <header className={classnames("topic-header", className)}>
      <nav className="topic-header--nav">
        <a href="/auto" className="topic-header--logo">
          <img src="//sensorsdata.cn/assets/img/logo_2018_white_7c1eb36.svg" alt="logo" className="icon-logo" />
        </a>
        {
          !hideMenu && <>
            <div className="navbar-toggle" onClick={() => setSpread(!spread)}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </div>
            <ul className={classnames("topic-header--menu", { open: spread })}>
              <li>
                <a href="/topic/ds2021.html">大会总览</a>
              </li>
              <li>
                <a href="/topic/ds2021star.html">星斗奖评选</a>
              </li>
              <li>
                <a href="/topic/ds2021Integral.html">积分有礼</a>
              </li>
            </ul>
          </>
        }
      </nav>
    </header>
  );
}

export default TopicHeader;